export function get_canvas_hw(im_w, im_h) {
    var IM_W = window.innerWidth;
    var aspect_ratio = im_w / im_h;
  
  
    var IM_H = 0;
    if (IM_W > 768) {
      var available_window_width = parseInt(IM_W * 0.6666) - 30;
      var available_window_height = window.innerHeight - 80
  
  
      if (im_w > available_window_width) {
        // If image width is greater than window width
        IM_W = available_window_width;
        IM_H = parseInt(IM_W / aspect_ratio);
  
  
        // Now we check if image height does not exceed window height
        while (IM_H > available_window_height) {
          IM_W -= 10
          IM_H = parseInt(IM_W / aspect_ratio);
        }
      } else {
        IM_W = im_w
        IM_H = parseInt(IM_W / aspect_ratio);
      }
    } else {
      IM_W -= 20
      IM_H = parseInt(IM_W / aspect_ratio)
    }
  
  
    if (IM_H > available_window_height) {
      IM_H = available_window_height
      IM_W = parseInt(IM_H * aspect_ratio)
    }
  
  
  
    return { new_w: IM_W, new_h: IM_H }
  }
  

  export function get_grid_rects(xmin, ymin, xmax, ymax, active_points){
    
    var w = xmax - xmin
    var h = ymax - ymin


    var allPoints = []
    var counter = 0;
    for (var i = 0; i < 2; i++) {
      for (var j = 0; j < 3; j++) {
        var x = xmin + w * (i / 2)
        var y = ymin + h * (j / 3)
        var x1 = xmin + w * ((i + 1) / 2)
        var y1 = ymin + h * ((j + 1) / 3)
        
        var fData = {
            points: [x, y, x1, y1], selected: false, hover: false
          }
        
        counter += 1
        
        
        allPoints.push(fData)
      }
    }

    var finalPoints = [
        allPoints[0], allPoints[3],
        allPoints[1], allPoints[4],
        allPoints[2], allPoints[5]
    ]
    
    for (var i = 0; i < active_points.length; i++) {
        if(active_points[i] == 1){
            finalPoints[i].selected = true
        }
    }

    
    return finalPoints
}



export const checkIfInsideRect = (x, y, rect) => {
    if (x >= rect[0] && x <= rect[2] && y >= rect[1] && y <= rect[3]) {
      return true;
    }
    return false;
  };