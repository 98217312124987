import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
  useParams, useNavigate
} from "react-router-dom";
import {
  get_canvas_hw, get_grid_rects, checkIfInsideRect
} from './utils'
import { collection, getDocs, doc, updateDoc, getDoc, where, query, limit } from "firebase/firestore";
import {
  db
} from '../../mdb'





function Grader() {
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const [canvasData, setCanvasData] = useState({
    height: 0, width: 0,
  });
  const [gridPoints, setGridPoints] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState({
    data: true, image: true
  })

  const user_id = localStorage.getItem("RDGuid")
  const [gradingIndex, setGradingIndex] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const match = useParams();
  const [imageData, setImageData] = useState(null);
  const [gradingData, setGradingData] = useState({
    points: [0, 0, 0, 0, 0, 0], quality: null, comments: "", user_id: user_id
  });

  const drawGrid = (xmin, ymin, xmax, ymax) => {
    setGridPoints(get_grid_rects(xmin, ymin, xmax, ymax, gradingData.points))
  };

  const drawGridPoints = () => {
    if (mainImage === null) return;

    // Draw the grid points for 3x3
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // draw image
    ctx.drawImage(mainImage, 0, 0, canvas.width, canvas.height);


    ctx.strokeStyle = '#03F';
    ctx.lineWidth = 2;
    ctx.beginPath();

    // draw rectangles
    for (var i = 0; i < gridPoints.length; i++) {
      var allPoints = gridPoints[i].points
      ctx.strokeRect(allPoints[0], allPoints[1], allPoints[2] - allPoints[0], allPoints[3] - allPoints[1]);

      // If hover, draw a red border
      if (gridPoints[i].hover) {
        // fill rect
        ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
        // Opacity of rectange

        ctx.fillRect(allPoints[0], allPoints[1], allPoints[2] - allPoints[0], allPoints[3] - allPoints[1]);
      }

      if (gridPoints[i].selected) {
        // fill rect
        ctx.fillStyle = 'rgba(0, 0, 255, 0.3)';
        // Opacity of rectange

        ctx.fillRect(allPoints[0], allPoints[1], allPoints[2] - allPoints[0], allPoints[3] - allPoints[1]);
      }
    }
    ctx.stroke();
  }

  useEffect(() => {

    drawGridPoints()
  }, [gridPoints])



  const get_normalized_points = (points, image_shape) => {
    const xmin = points[0] / image_shape[0]
    const ymin = points[1] / image_shape[1]
    const xmax = points[2] / image_shape[0]
    const ymax = points[3] / image_shape[1]
    return [xmin, ymin, xmax, ymax]
  }

  useEffect(() => {
    if (mainImage === null) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    var normalizedPoints = get_normalized_points(imageData.bbox, [mainImage.width, mainImage.height])
    var xmin = normalizedPoints[0] * canvas.width
    var ymin = normalizedPoints[1] * canvas.height
    var xmax = normalizedPoints[2] * canvas.width
    var ymax = normalizedPoints[3] * canvas.height

    drawGrid(xmin, ymin, xmax, ymax);
  }, [mainImage])




  const onMouseMove = (e) => {
    var gridPointsLocal = [...gridPoints];
    // Iterate through all the grid points and check if the mouse is inside
    for (var i = 0; i < gridPointsLocal.length; i++) {
      var allPoints = gridPointsLocal[i].points
      if (checkIfInsideRect(e.offsetX, e.offsetY, allPoints)) {
        gridPointsLocal[i].hover = true;
      } else {
        gridPointsLocal[i].hover = false;
      }
    }
    setGridPoints(gridPointsLocal)
  }


  const onMouseClick = (e) => {
    var gridPointsLocal = [...gridPoints];
    for (var i = 0; i < gridPointsLocal.length; i++) {
      var allPoints = gridPointsLocal[i].points
      if (checkIfInsideRect(e.offsetX, e.offsetY, allPoints)) {
        let oldPoints = [...gradingData.points];

        if (gridPointsLocal[i].selected) {
          oldPoints[i] = 0
          gridPointsLocal[i].selected = false
        } else {
          oldPoints[i] = 1
          gridPointsLocal[i].selected = true
        }
        setGradingData({
          ...gradingData,
          points: oldPoints
        })


      }
    }
    setGridPoints(gridPointsLocal)

  }
  const loadCanvasImage = (url) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = url;//'http://localhost:3000/Screenshot (115).png' //'https://source.unsplash.com/random/8=900x900';
    image.onload = () => {
      setIsLoading({ ...isLoading, image: false })
      var Nh = get_canvas_hw(image.width, image.height)
      canvas.height = Nh.new_h
      canvas.width = Nh.new_w

      setCanvasData({
        height: Nh.new_h,
        width: Nh.new_w,
      });
      setMainImage(image)
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    };
  }


  useEffect(() => {
    if (imageData !== null) {
      loadCanvasImage(imageData.url)
    }
  }, [imageData])

  const get_image_data = async () => {
    const docRef = doc(db, "images", match.id);
    const docSnap = await getDoc(docRef);

    let docData = docSnap.data();
    setImageData(docData);

    if (docData.grading !== undefined) {
      // filter docData.grading where user_id === user_id

      let gradingIndex = null
      for (var i = 0; i < docData.grading.length; i++) {
        if (docData.grading[i].user_id === user_id) {
          gradingIndex = i
          break
        }
      }

      if (gradingIndex !== null) {
        setGradingData(docData.grading[gradingIndex])
        setGradingIndex(gradingIndex)
      }


      //setGradingData(docData.grading)
    }

    setIsLoading({ ...isLoading, data: false })

  }

  useEffect(() => {
    if (imageData === null) {
      get_image_data()
    }
  }, []);

  // Add event listener for mouse
  useEffect(() => {
    if (isLoading.data) { return }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.addEventListener('mousemove', onMouseMove);
    canvas.addEventListener('click', onMouseClick);
    return () => {
      canvas.removeEventListener('mousemove', onMouseMove);
      canvas.removeEventListener('click', onMouseClick);
    }

  }, [gridPoints]);


  const submitGrading = async () => {

    if (gradingData.quality === null) {
      alert("Please select a quality")
      return
    }

    setIsSubmitting(true)
    // update document
    const docRef = doc(db, "images", match.id);

    const docSnap = await getDoc(docRef);

    let docData = docSnap.data();

    if (docData.grading === undefined) {
      docData.grading = []
    }

    var gradingDataLocal = [...docData.grading]
    if (gradingIndex !== null) {
      gradingDataLocal[gradingIndex] = gradingData
    }
    else {
      gradingDataLocal.push(gradingData)
    }


    await updateDoc(docRef, {
      grading: gradingDataLocal,
      status: "graded"
    })


    // Get another not graded image
    var doc_data = await getDocs(
      query(collection(db, 'images'), where('status', "==", "not-graded"), limit(1))
    )

    var doc_ids = []
    doc_data.forEach(doc => {
      doc_ids.push(doc.id)
    })

    setIsSubmitting(false)
    if (doc_ids.length > 0) {
      // push using windows
      window.location.href = `/grade/${doc_ids[0]}`
    }
  }
  const containerStyle = {
    height: "calc(100vh - 40px)",
    ...(isMobile && { paddingTop: "10px", height: "calc(50vh - 20px)" }) // Apply paddingTop only for mobile view
  };

  return (
    <div>
      <Navbar />
      <Grid container style={containerStyle}>
        <Grid item xs={12} md={9}>
          <div style={{ backgroundColor: "rgb(245, 243, 240)", height: "100%", width: "100%", textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {isLoading.image ?
              <div>Loading Image...</div>
              : null}
            <canvas ref={canvasRef} style={{ backgroundColor: "#000", height: canvasData.height, width: canvasData.width }}></canvas>

          </div>
        </Grid>
        <Grid item xs={12} md={3} style={{ paddingLeft: "0px" }}>
          <div style={{ backgroundColor: "rgb(245, 243, 240)", height: "100%", width: "100%", overflowY: "hidden" }}>
            <div style={{ padding: "20px", borderLeft: "1px solid #ccc", height: "100%" }}>
              <h3>Grade</h3>
              <div>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Select Grade</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={gradingData.quality}
                    onChange={(e) => setGradingData({
                      ...gradingData, quality: e.target.value
                    })}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value="mild" control={<Radio />} label="Mild" />
                    <FormControlLabel value="moderate" control={<Radio />} label="Moderate" />
                    <FormControlLabel value="severe" control={<Radio />} label="Severe" />
                  </RadioGroup>
                </FormControl>

                <div style={{ marginTop: "30px" }}>
                  <FormLabel id="demo-radio-buttons-group-label">CXR Report</FormLabel>
                  <textarea onChange={(e) => setGradingData({
                    ...gradingData, comments: e.target.value
                  })} rows={5}
                    value={gradingData.comments}
                    style={{
                      padding: "10px 10px", marginTop: "10px",
                      borderRadius: "3px", outline: "none", border: "1px solid #ccc", width: "80%"
                    }} placeholder="Report">
                    {/* {gradingData.comments} */}
                  </textarea>

                </div>

                <div>
                  {isLoading.image ?
                    null :
                    <Button disabled={isSubmitting ? true : false} onClick={() => submitGrading()} variant="contained" color="primary" style={{ marginTop: "30px" }}>
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  }
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>

  );
}

export default Grader;