import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Home from '../Home/Home';

import { auth, provider } from '../../mdb/index';
import { signInWithPopup } from 'firebase/auth';
import "./style.css";

const Signin = () => {
    const navigate = useNavigate();

    const loginWithGoogle = () => {
        signInWithPopup(auth, provider).then((data) => {
            console.log(data)
            localStorage.setItem("email", data.user.email)

            localStorage.setItem("RDGname", data.user.displayName)
            localStorage.setItem("RDGphoto", data.user.photoURL)
            localStorage.setItem("RDGuid", data.user.uid)

            const email = !!localStorage.getItem('email')
            if (email) {
                navigate('/')
            }
        })
    }

    return (
        <div className="centered-content">
            <a className="google-btn" onClick={loginWithGoogle}>
                <div className="google-icon-wrapper">
                    <img className="google-icon" src="./google-logo.png" alt="Google logo" />
                </div>
                <div className="btn-text" ><b>Sign in with google</b></div>
            </a>
        </div>
    )
}

export default Signin