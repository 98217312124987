import React, { useState, setState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import PrivateRoute from './components/PrivateRouter';
import Grader from './Pages/Grader/Grader';
import Home from './Pages/Home/Home';
import Upload from './Pages/Upload/upload';
import Signin from './Pages/Signin/Signin';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/grade/:id" element={<Grader />} />
          <Route path="/upload" element={<Upload />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;