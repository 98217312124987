import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import FilterListIcon from '@mui/icons-material/FilterList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import { useDropzone } from 'react-dropzone'
import { Container } from "@mui/system";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Navbar from "../../components/Navbar";
import { collection, addDoc, arrayUnion, getDocs, doc, updateDoc, getDoc, where, query, setDoc } from "firebase/firestore";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import {
    db
} from '../../mdb'

const imagesRef = collection(db, "images")
const storage = getStorage();
export default function Upload() {

    const [images, setImages] = useState([])
    const [isUploading, setIsUploading] = useState(false)

    const updateStatus = (index, status) => {
        let temp_imgs = [...images]
        temp_imgs[index].status = status
        setImages(temp_imgs)
    }
    const uploadSingleFile = async(index) => {
        updateStatus(index, "uploading")
        // Check if file exists in firebase
        const docRef = query(imagesRef, where('name', '==', images[index].file.name));
        const docSnap = await getDocs(docRef);
        if(docSnap.docs.length > 0){
            return updateStatus(index, "exists")
        }
        



        const spaceRef = ref(storage, 'images/' + images[index].file.name);
        await uploadBytes(spaceRef, images[index].file)

        var url = await getDownloadURL(spaceRef)

        // Store url and name in firestore
        
        await addDoc(imagesRef, {
            name : images[index].file.name,
            url : url,
            datetime: new Date().toLocaleString(),
            status: "not-graded"
        })
        updateStatus(index, "uploaded")

    }

    const uploadImages = async() => {
        setIsUploading(true)
        for(var i = 0; i<images.length; i++){
            if(images[i].status != "uploaded" || images[i].status != "uploading")
                await uploadSingleFile(i)
        }
        setIsUploading(false)
    }


    const onDrop = useCallback(acceptedFiles => {
        let user_temp = [...images];
        for(var i = 0; i<acceptedFiles.length; i++){
            user_temp.push({status: "queue", file: acceptedFiles[i]})
        }
        setImages(user_temp)
    }, [images])


    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
        onDrop,
        accept:'image/jpg,image/jpeg,image/png',
        multiple:true
     })


    return (
        <>
            <Navbar />
            <div style={{ backgroundColor: "#fafbfe", width: "100%", height: "94vh", verticalAlign: "middle" }}>
                <Container>
                    <div>
                        <h2 >Upload Images</h2>
                    </div>
                    <div {...getRootProps()} style={{ 
                        border: "2px dashed #dee2e6",
                        background: "#fff",
                        borderRadius: "6px",
                        cursor: "pointer",
                        minHeight: "150px",
                        padding: "20px"
                    }}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Drop the files here ...</p> :
                                <div style={{ textAlign: 'center' }}>
                                
                                <h4 style={{ marginTop: "20px" }}>Drag 'n' drop some files here, or click to select files</h4>
                                <p>Image upload will start automatically.</p>
                                </div>
                        }
                    </div>
                    {  !isUploading  ? 
                    <Button variant="contained" onClick={() => uploadImages()} color="primary" style={{ marginTop: "15px" }}>
                        Upload Images
                    </Button>
                    : null }

{
                        images.length > 0 ?
                        <div>
                            { images.map((item, index) => {
                                return (
                                    <div key={index} style={{ marginTop: "20px", border: "1px solid #ccc", borderRadius: 5, padding: "10px 5px" }}>
                                       
                                        <div style={{ float: "left" }}>
                                        <h5 style={{ color: "#98a6ad", fontSize: "0.9rem", marginBottom: "5px" }}>{item.file.name.slice(0, 50)}</h5>
                                        <strong>{ Math.round(item.file.size/1024/1024 * 100, 2)/100 } Mb</strong>
                                        </div>
                                        <div style={{ float: "right" }}>
                                            { item.status == "queue" ?
                                            <HighlightOffIcon style={{ margin: "10px 15px", cursor: "pointer" }} />
                                            :
                                            (
                                                item.status == "uploading" ?
                                                <CircularProgress size={25} style={{ margin: "10px 15px"}} />
                                                :
                                                (
                                                    item.status == "uploaded" ?
                                                    <CheckCircleIcon style={{ margin: "10px 15px", cursor: "pointer", color: "green" }} />
                                                    :
                                                    ( item.status == "exists" ?
                                                    <CheckCircleIcon style={{ margin: "10px 15px", cursor: "pointer", color: "red" }} />
                                                    :
                                                    <HighlightOffIcon style={{ margin: "10px 15px", cursor: "pointer", color: "red" }} />
                                                    )
                                                )
                                            )    
                                        
                                        }
                                        </div>
                                        <div style={{ clear: "both" }}></div>
                                    </div>
                                )
                            }) }
                        </div>
                        :
                        null
                    }
                </Container>
            </div>
        </>
    );
}
