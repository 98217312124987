import { Navigate, Outlet } from "react-router-dom"

const PrivateRoute = () => {

    const userLoggedIn = (!!localStorage.getItem('email'))
    console.log(userLoggedIn)
    return (
        userLoggedIn ? <Outlet /> : <Navigate to='/signin' />
    )
}
export default PrivateRoute