import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import styled from 'styled-components';
import { collection, getDocs, query } from "firebase/firestore";

import { Container, Grid, Card, CardMedia, CardContent, CardActions, Typography, Button } from '@mui/material';
import {
    db
} from '../../mdb';
// Import check icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Home() {
    const [images, setImages] = useState([])
    const [isLoading, setIsloading] = useState(true)
    const [filters, setFilters] = useState({
        limit: 20, type: "not-graded"
    })
    const user_id = localStorage.getItem("RDGuid")

    const fetchImages = async () => {
        setIsloading(true)
        var images = null;
        if (filters.type !== "all") {
            if (filters.limit !== "all") {
                images = await getDocs(
                    query(collection(db, 'images'))
                )
            } else {
                images = await getDocs(
                    query(collection(db, 'images'))
                )
            }
        } else {
            if (filters.limit !== "all") {
                images = await getDocs(
                    query(collection(db, 'images'))
                )
            } else {
                images = await getDocs(
                    query(collection(db, 'images'))
                )
            }
        }


        let images_arr = []
        images.forEach((doc) => {
            const img_data = doc.data()
            img_data.id = doc.id;

            if (filters.limit !== "all" && images_arr.length < filters.limit) {
                if (filters.type === "graded") {
                    if (img_data.grading?.find((item) => item.user_id === user_id)) {
                        images_arr.push(img_data);
                    }
                } else {
                    if (!img_data.grading?.find((item) => item.user_id === user_id)) {
                        images_arr.push(img_data);
                    }
                }
            } else {
                if (filters.type === "graded") {
                    if (img_data.grading?.find((item) => item.user_id === user_id)) {
                        images_arr.push(img_data);
                    }
                } else {
                    if (!img_data.grading?.find((item) => item.user_id === user_id)) {
                        images_arr.push(img_data);
                    }
                }
            }

            // if limit is reached


        })
        setImages(images_arr)
        setIsloading(false)
    }

    useEffect(() => {
        fetchImages()
    }, [filters])

    const StyledContainer = styled(Container)`
        margin-top: 3rem;

        @media (max-width: 768px) {
            margin-top: .5rem;
        
        h1 {
            font-size: 1.5rem;
            margin-top: 1.5rem;
          }
          select{
            margin-left: 10px;
          }
        }
        `;

    return (
        <div>
            <Navbar />
            <StyledContainer maxWidth="lg">
                {/* Create fluid container */}
                <Container maxWidth="lg">
                    <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: '10px' }}>
                        <h1 style={{ padding: 0, margin: 0 }}>Images ({images.length})</h1>
                        <div className="select" style={{ padding: "0px 0px" }}>
                            <select style={{ padding: "10px" }} value={filters.type} onChange={(e) => setFilters({ ...filters, type: e.target.value })}>
                                <option value="all">All</option>
                                <option value="graded">Graded</option>
                                <option value="not-graded">Not Graded</option>
                            </select>
                            <select style={{ padding: "10px", marginLeft: "10px" }} value={filters.limit} onChange={(e) => setFilters({ ...filters, limit: e.target.value })}>
                                <option value={20}>20</option>
                                <option value={28}>28</option>
                                <option value={40}>40</option>
                                <option value={52}>52</option>
                                <option value={78}>78</option>
                                <option value={96}>96</option>
                                <option value={'all'}>All</option>
                            </select>
                        </div>
                    </div>
                    {isLoading ?
                        <div style={{ padding: "100px 0px", textAlign: "center", fontSize: 22 }}>Loading...</div> :
                        <Grid container>
                            {images.map((item) => {
                                return (
                                    <Grid item xs={12} sm={12} md={3} style={{ padding: "10px" }} key={`img-${item.id}`}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                height="170"
                                                image={item.url}
                                                alt="green iguana"
                                            />
                                            <CardContent>
                                                <Typography sx={{
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 2,
                                                }} gutterBottom variant="body" component="div">
                                                    {item.name}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button href={`/grade/${item.id}`} size="small">{
                                                    item.grading?.find((item) => item.user_id === user_id)
                                                        ? `Edit Grading` : `Start Grading`
                                                }</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )
                            })
                            }
                        </Grid>
                    }
                </Container>
            </StyledContainer>
        </div>
    );
}
